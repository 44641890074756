export const types = {

    uiOpenModal: '[ui-event] Open Modal',
    uiCloseModal: '[ui-event] Close Modal',

    eventSetActive: '[calendar-event] Set Active',
    eventRemoveActive: '[calendar-event] Remove Active',
    eventStartAddNew: '[calendar-event] Start Add New',
    eventAddNew: '[calendar-event] Add New Event',
    eventUpdated: '[calendar-event] Event Updated',
    eventDeleted: '[calendar-event] Event Deleted',
    eventLoaded: '[calendar-event] Event Loaded',
    eventLogout: '[calendar-event] Event Logout',
    

    eventSelectedDate: '[calendar-event] Selected Date',
    eventRemoveSelectedDate: '[calendar-event] Remove Selected Date',

    authCheckingFinish: '[auth-event] Finish checking login state',
    authStartLogin: '[auth-event] Start Loging',
    authLogin: '[auth-event] Login',
    authStartRegister: '[auth-event] Start Register',
    authStartTokenRenew: '[auth-event] Start token renew',
    authLogout: '[auth-event] Logout'

}